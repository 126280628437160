<template>
  <div style="background-color: #333439;">
    <div class="show-achievement w1120">
      <div class="top">
        <line-title
          :title="`无锡零探数字能源科技有限公司成就`"
          :color="`#FFFFFF`"
          :lineColor="`#C4C4C4`"
        ></line-title>
      </div>
      <div
        class="content"
        @mouseover="stopTran"
        @mouseout="continueTran"
        ref="copycontentRef"
      >
        <div class="moveDiv" :style="styleCss">
          <div
            class="content-item"
            v-for="(item, index) in imgList"
            :key="index"
          >
            <div class="nr">
              <img :src="item" alt="" style="width: auto; height: 210px;" />
            </div>
          </div>
          <div
            class="content-item"
            v-for="(item, index) in imgList"
            :key="index + 'img'"
            ref="copyListRef"
          >
            <div class="nr">
              <img :src="item" alt="" style="width: auto; height: 210px;" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "@/assets/homeImage/zhengshu1.png";
import img2 from "@/assets/homeImage/zhengshu2.png";
import img3 from "@/assets/homeImage/zhengshu3.png";
import img4 from "@/assets/homeImage/zhengshu4.png";
import img5 from "@/assets/homeImage/zhengshu5.png";
import img6 from "@/assets/homeImage/zhengshu6.png";
import img7 from "@/assets/homeImage/zhengshu7.png";
import img8 from "@/assets/homeImage/zhengshu8.png";
import img9 from "@/assets/homeImage/zhengshu9.jpg";
import img10 from "@/assets/homeImage/zhengshu10.jpg";
import img11 from "@/assets/homeImage/zhengshu11.jpg";
import img12 from "@/assets/homeImage/zhengshu12.jpg";
export default {
  data() {
    return {
      timer: null,
      left: 0,
      imgList: [
        img1,
        img7,
        img8,
        img2,
        img3,
        img4,
        img10,
        img11,
        img12,
        img9,
        img5,
        img6,
      ],
    };
  },
  computed: {
    styleCss() {
      return {
        left: `-${this.left}px`,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.transImg();
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    continueTran() {
      this.transImg();
    },
    stopTran() {
      clearInterval(this.timer);
    },
    transImg() {
      this.timer = setInterval(() => {
        if (
          Math.abs(
            this.$refs.copyListRef[0].getBoundingClientRect().left -
              this.$refs.copycontentRef.offsetLeft
          ) <= 1
        ) {
          this.left = 0;
        }
        this.left += 1;
      }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.show-achievement {
  height: 525px;
  .top {
    padding-top: 69px;
  }
  .content {
    width: 1120px;
    overflow: hidden;

    .moveDiv {
      width: 100%;
      height: 225px;
      position: relative;
      display: flex;
      margin-top: 77px;
      padding-bottom: 10px;
    }
    .content-item {
      width: auto;
      height: 210px;
      border-radius: 25px;
      margin-right: 31px;
      flex-shrink: 0;
      position: relative;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
