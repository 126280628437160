<template>
  <div class="about-us">
    <div class="header-left w1120">
      <div class="about-header-content">
        <div class="left-content">
          <div class="left-title">
            关于零探
          </div>
          <p>
            无锡零探数字能源科技有限公司，是一家专注于数字孪生可视化、虚拟现实交互和3D引擎算法的研发型项目企业。<br />公司致力于数字孪生技术领域的三维可视化研发与应用，将真实世界场景数字化，交付以人为本的数字化场景。<br />公司员工均为80后、90后软件及数字化人才，核心团队来自字节跳动、网易等行业领导企业，为西门子、博世、霍尼韦尔、华为、国家电网、远景能源、远东电缆等500强客户提供数字化产品和服务，曾参与北京冬奥会、进博会、上海国际车展等大型活动并提供数字化设计服务。
          </p>
        </div>
        <div class="header-right"></div>
      </div>
    </div>
    <ShowCulture></ShowCulture>
    <ShowState></ShowState>
    <ShowAchievement></ShowAchievement>
    <MtData></MtData>
    <ShowTrust></ShowTrust>
  </div>
</template>

<script>
import ShowCulture from "../home/components/show-culture/index.vue";
import ShowState from "../home/components/show-state/index.vue";
import ShowAchievement from "../home/components/show-achievement/index.vue";
import ShowTrust from "../home/components/show-trust/index.vue";
import MtData from "../home/components/mt-data/index.vue";
export default {
  components: {
    ShowCulture,
    ShowState,
    ShowAchievement,
    ShowTrust,
    MtData,
  },
};
</script>
<style lang="scss" scoped>
.header-left {
  text-align: left;
  padding: 203px 0;
  margin-bottom: 25px;
  line-height: 56px;
  .about-header-content {
    display: flex;
    .left-content {
      .left-title {
        color: #333439;
        font-size: 40px;
        line-height: 56px;
        margin-bottom: 26px;
        font-weight: 500;
        font-style: Medium;
      }
      p {
        text-align: justify;
        width: 455px;
        font-size: 18px;
        line-height: 40px;
        color: #707174;
      }
    }
    .header-right {
      margin-top: 15px;
      margin-left: 61px;
      width: 604px;
      height: 428px;
      background-image: url("../../../../assets/joinus/about-img.png");
      background-size: cover;
      filter: drop-shadow(-16px -16px 40px rgba(255, 255, 255, 0.8))
        drop-shadow(16px 4px 64px rgba(18, 61, 101, 0.2));
    }
  }
}
</style>
